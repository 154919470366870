import * as React from "react";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  OverlayTrigger,
  Form,
  InputGroup,
  Button,
  Accordion
} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import Avatar from "react-avatar";
import _ from "lodash";
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { setActiveFacility, activeFacility, menuIsOpenValue, setMenuIsOpen, setAuditKey, auditKeyVal, disableSaveButton, setDisableSaveButton } from "./operations/checkinCheckoutSlice";
import moment from "moment-timezone";
import { CustomDropdown } from "../../components/commons/CustomDropdown";
import { scheduleListLoading, setShowPopover, showPopoverVal } from "./operations/scheduleListSlice";
import { onCallScheduleListLoading } from "./operations/onCallScheduleListSlice";
import { phoneFormat } from "../../app/utils/testUtils";
import { BACKLOG_SECTION_TYPE, INACTIVE_STATUS, NON_SERVICE_TYPE, SURGE_TYPE, compareTimes, convertToMMDDYYYY } from "../../app/utils/Constants";

const timeRegex = new RegExp(':', 'g')

const getClassNameByStatus = (
  sectionType,
  eventType,
  status,
  facility = {}
) => {
  let result = "";
  if (sectionType === "scheduleBoard") {
    if (!status || status === "" || status === "ACTIVE") {
      result =
        eventType === "Scheduled Service Day"
          ? "assigned-on-calender"
          : eventType === SURGE_TYPE ? "surge-facility-default" : eventType === NON_SERVICE_TYPE ? "nonservice-facility-default" : "default-assigned-calender";
    } else if (status === "Check In") {
      result = eventType === SURGE_TYPE ? "checked-in-surge" : eventType === NON_SERVICE_TYPE ? "checked-in-nonservice" : "checked-in-calender";
    } else if (status === "Check Out") {
      result = eventType === SURGE_TYPE ? "checked-out-surge" : eventType === NON_SERVICE_TYPE ? "checked-out-nonservice" : "visit-calender-complete";
    } else if (status === "Spin") {
      result = eventType === SURGE_TYPE ? "checked-out-surge" : eventType === NON_SERVICE_TYPE ? "checked-out-nonservice" : "visit-calender-complete";
    }
  } else {
    const {
      users = [],
      service_stop_day,
      check_out_count,
      check_in_count,
      status
    } = facility;
    if (service_stop_day == 1) {
      if (users.length > 0) {
        if (check_in_count === 0 && check_out_count === 0) {
          result =
            eventType === "Scheduled Service Day"
              ? "assigned"
              : "inactive-assigned";
        } else if (check_in_count === 1 && check_out_count === 0) {
          result = "checked-in";
        } else if (check_in_count === 1 && check_out_count === 1) {
          result = "visit-complete";
        }
      } else {
        result = eventType === "Scheduled Service Day" ? "active" : "inactive";
      }
    } else {
      if (users.length > 0) {
        if (users.length === 1) {
          if (check_in_count === 0 && check_out_count === 0) {
            result = "assigned-1X sc-border-red";
          } else {
            result = "assigned-2X-combo sc-border-red";
          }
        } else {
          if (check_in_count === 0 && check_out_count === 0) {
            result = "assigned-2X-default";
          } else if (check_in_count === 2 && check_out_count === 2) {
            result = "assigned-2X-combo visit-complete";
          } else {
            result = "assigned-2X-combo";
          }
        }
      } else {
        result = "active-2X";
      }
    }
    // inactive check
    if (status === INACTIVE_STATUS) {
      result = result + " inactive-facility";
    }
  }
  return result;
};

const constructTimeOptions = () => {
  let options = [];
  for (let i = 0; i < 24; i++) {
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:00`,
      value: `${i < 10 ? "0" : ""}${i}:00`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:15`,
      value: `${i < 10 ? "0" : ""}${i}:15`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:30`,
      value: `${i < 10 ? "0" : ""}${i}:30`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:45`,
      value: `${i < 10 ? "0" : ""}${i}:45`,
    });
  }
  options = options.map((opt, index) => {
    return { ...opt, id: index };
  });
  return options;
};

export const Facility = React.memo((props) => {
  const dispatch = useDispatch();
  const activeFacilityObj = useSelector(activeFacility);
  const showPopover = useSelector(showPopoverVal);

  const {
    facilities,
    userIndex,
    serviceIndex,
    zoneIndex,
    routineIndex,
    sectionType,
    date,
    eventType,
    constructPyloadForCheckinCheckout,
    constructPayloadToClearCheckinCheckoutSpin,
    getScheduleBoardData,
    blockId,
    uniqueServiceId,
    hasRouteStarted,
    showDropArea,
    lastFacilityId,
    timezone_ids,
    showInactiveFac,
    viewAttachment
  } = props;
  let droppableId = "";

  const menuIsOpen = useSelector(menuIsOpenValue);
  const auditKey = useSelector(auditKeyVal);
  const scheduleListLoader = useSelector(scheduleListLoading);
  const onCallScheduleListLoader = useSelector(onCallScheduleListLoading);
  const disableSaveButtonAfterApiCall = useSelector(disableSaveButton)
  const checkInRef = React.useRef(null);
  const checkOutRef = React.useRef(null);
  const stopDayRef = React.useRef(null);
  const spinTimeRef = React.useRef(null);

  const checkInTimezoneRef = React.useRef(null);
  const checkOutTimezoneRef = React.useRef(null);
  const spinTimeTimezoneRef = React.useRef(null);

  const [reRender, setReRender] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [showErrorSpin, setShowErrorSpin] = React.useState(false);

  let permission =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
    [];

  permission = permission.filter((perm) => perm.page === "events");
  const eventAccess = permission.length === 1 ? permission[0].access : [];
  const canDeleteFacility = eventAccess && eventAccess.find((item) => item === "relation-delete") === "relation-delete" ? true : false;
  const canClearCheckinCheckout = eventAccess && eventAccess.find((item) => item === "delete-routine-action") === "delete-routine-action" ? true : false;
  const canDeleteSurgeFacility = eventAccess && eventAccess.find((item) => item === "relation-delete-surge") === "relation-delete-surge" ? true : false;
  const canDeleteNonServiceFacility = eventAccess && eventAccess.find((item) => item === "relation-delete-non-service") === "relation-delete-non-service" ? true : false;
  const isExecutive = eventAccess && eventAccess.find((item) => item === "routine-bulk-action") === "routine-bulk-action" ? true : false;

  if (userIndex === undefined) {
    droppableId =
      BACKLOG_SECTION_TYPE + "|" + serviceIndex + "|" + zoneIndex + "|" + routineIndex;
  } else {
    droppableId =
      `user/${blockId}/${uniqueServiceId}` +
      "|" +
      userIndex +
      "|" +
      serviceIndex +
      "|" +
      zoneIndex +
      "|" +
      routineIndex;
  }

  const togglePopOver = (id, blockId, facilityIndex, facility, userIndex) => {
    let tempFacility = {};
    if (showPopover === `${userIndex}-${blockId}-${id}-${facilityIndex}-${props.event_id}`) {
    } else {
      tempFacility = { ...facility };
      let { action = [] } = tempFacility;
      if (action && action.length) {
        let newAction = action.map(act => {
          let obj = {};
          if (act.timezone) {
            obj.timezone = {
              ...act.timezone,
              label: act.timezone.timezone_name,
              value: act.timezone.timezone_id
            }
            obj.action_type = act.action_type;
            obj.action_id = act.action_id;
          } else if (facility.timezone && facility.timezone.timezone_id) {
            obj.timezone = { ...facility.timezone, label: facility.timezone.timezone_name, value: facility.timezone.timezone_id }
          }
          obj.action_attachments = act.action_attachments || [];
          return obj;
        });
        if (facility.timezone && facility.timezone.timezone_id) {
          if (!newAction.some(act => act.action_type === "Check In")) {
            newAction.push({
              action_type: "Check In",
              timezone: { ...facility.timezone, label: facility.timezone.timezone_name, value: facility.timezone.timezone_id }
            })
          }
          if (!newAction.some(act => act.action_type === "Check Out")) {
            newAction.push({
              action_type: "Check Out",
              timezone: { ...facility.timezone, label: facility.timezone.timezone_name, value: facility.timezone.timezone_id }
            })
          }
          if (!newAction.some(act => act.action_type === "Spin")) {
            newAction.push({
              action_type: "Spin",
              timezone: { ...facility.timezone, label: facility.timezone.timezone_name, value: facility.timezone.timezone_id }
            })
          }
        }
        tempFacility.newAction = newAction;
      } else {
        if (facility.timezone && facility.timezone.timezone_id) {
          tempFacility.newAction = [
            {
              action_type: "Check In",
              timezone: { ...facility.timezone, label: facility.timezone.timezone_name, value: facility.timezone.timezone_id }
            },
            {
              action_type: "Check Out",
              timezone: { ...facility.timezone, label: facility.timezone.timezone_name, value: facility.timezone.timezone_id }
            },
            {
              action_type: "Spin",
              timezone: { ...facility.timezone, label: facility.timezone.timezone_name, value: facility.timezone.timezone_id }
            }
          ]
        }
      }
    }
    dispatch(setShowPopover(showPopover === `${userIndex}-${blockId}-${id}-${facilityIndex}-${props.event_id}` ? "" : `${userIndex}-${blockId}-${id}-${facilityIndex}-${props.event_id}`));
    dispatch(setActiveFacility(tempFacility));
  };

  const updateCheckinCheckout = (
    longitude,
    latitude,
    facility_id,
    facilityIndex
  ) => {
    const { newAction = [], notes, event_has_facility_id, note, isClear, clearNote = "", action = [], selectedFiles = [] } = activeFacilityObj;
    if (isClear) {
      let payload = {};
      action.forEach((act) => {
        if (act.action_type === "Check In") {
          payload.id = act.action_id;
          payload.checkin_action_id = act.action_id;
        }
        if (act.action_type === "Check Out") {
          payload.checkout_action_id = act.action_id;
        }
        if (act.action_type === "Spin") {
          payload.spin_action_id = act.action_id;
        }
      });
      if (payload && Object.keys(payload).length > 0) {
        payload.delete_notes = clearNote;
        dispatch(setDisableSaveButton(true));
        constructPayloadToClearCheckinCheckoutSpin(
          payload,
          userIndex,
          serviceIndex,
          uniqueServiceId,
          zoneIndex,
          routineIndex,
          facilityIndex
        );
      }
    } else {
      let actionData = [];
      newAction.forEach((act) => {
        if (act.action_type === "Check In") {
          let oiginalCheckInObject = action.filter(
            (act) => act.action_type === "Check In"
          )
            ? action.filter(
              (act) => act.action_type === "Check In"
            )[0]
              ? action.filter(
                (act) => act.action_type === "Check In"
              )[0]
              : {}
            : {};
          let dateTime = act.action_datetime && act.action_datetime.value ? `${moment(date).format("YYYY-MM-DD")} ${act.action_datetime.value}` : oiginalCheckInObject.action_datetime;
          if (dateTime) {
            actionData.push({
              id: act.action_id || 0,
              action_type_id: 1,
              action_datetime: dateTime,
              hospital_id: null,
              lab_id: null,
              is_courier_drop_off: false,
              facility_id: facility_id,
              event_has_facility_id,
              latitude,
              longitude,
              order_id: 0,
              user_id: props.user_id,
              event_id: props.event_id,
              notes: note,
              timezone_id: act.timezone ? act.timezone.value : ""
            });
          }
        }
        if (act.action_type === "Check Out") {
          let originalCheckOutObject = action.filter(
            (act) => act.action_type === "Check Out"
          )
            ? action.filter(
              (act) => act.action_type === "Check Out"
            )[0]
              ? action.filter(
                (act) => act.action_type === "Check Out"
              )[0]
              : {}
            : {};
          let dateTime = act.action_datetime && act.action_datetime.value ? `${moment(date).format("YYYY-MM-DD")} ${act.action_datetime.value}` : originalCheckOutObject.action_datetime;
          if (dateTime) {
            actionData.push({
              id: act.action_id || 0,
              action_type_id: 2,
              action_datetime: dateTime,
              hospital_id: null,
              lab_id: null,
              is_courier_drop_off: false,
              facility_id: facility_id,
              event_has_facility_id,
              latitude,
              longitude,
              order_id: 0,
              user_id: props.user_id,
              event_id: props.event_id,
              notes: note,
              timezone_id: act.timezone ? act.timezone.value : ""
            });
          }
        }
        if (act.action_type === "Spin" && act.action_datetime && act.action_datetime.value) {
          let originalSpinObject = action.filter(
            (act) => act.action_type === "Spin"
          )
            ? action.filter(
              (act) => act.action_type === "Spin"
            )[0]
              ? action.filter(
                (act) => act.action_type === "Spin"
              )[0]
              : {}
            : {};

          let dateTime = act.action_datetime && act.action_datetime.value ? `${moment(date).format("YYYY-MM-DD")} ${act.action_datetime.value}` : originalSpinObject.action_datetime;
          if (dateTime) {
            actionData.push({
              id: act.action_id || 0,
              action_type_id: 6,
              action_datetime: dateTime,
              hospital_id: null,
              lab_id: null,
              is_courier_drop_off: false,
              facility_id: facility_id,
              event_has_facility_id,
              latitude,
              longitude,
              order_id: 0,
              user_id: props.user_id,
              event_id: props.event_id,
              notes: note,
              timezone_id: act.timezone ? act.timezone.value : ""
            });
          }
        }
      });
      const formData = new FormData();
      formData.append("actions", JSON.stringify(actionData));
      if (selectedFiles && selectedFiles.length) {
        selectedFiles.forEach((file) => {
          formData.append("files", file);
        });
      }
      if (notes) {
        formData.append("event_has_facility_notes", JSON.stringify({
          event_has_facility_id,
          event_has_facility_notes: notes.value
        }));
      }
      dispatch(setDisableSaveButton(true));
      constructPyloadForCheckinCheckout(
        formData,
        userIndex,
        serviceIndex,
        uniqueServiceId,
        zoneIndex,
        routineIndex,
        facilityIndex
      );
    }
  };

  const isDragPermitted = (facility, sectionType, eventType) => {
    if (sectionType === BACKLOG_SECTION_TYPE) {
      if (facility.status === INACTIVE_STATUS) {
        return false;
      }
      if (facility.service_stop_day == 1) {
        if (facility.users.length) {
          return false;
        }

        if (facility.check_in_count || facility.check_out_count) {
          return false;
        }
      } else {
        // 2X facility
        if (facility.users.length == 2) {
          return false;
        }
      }
    } else {
      // board
      if (facility.status === INACTIVE_STATUS) {
        return false;
      } else if (eventType === SURGE_TYPE || eventType === NON_SERVICE_TYPE) {
        return false
      }
    }

    return true;
  };

  const updateCheckinCheckoutData = (data = [], is2x = false) => {
    let tempActiveFacility = { ...activeFacilityObj };
    tempActiveFacility.newAction = data;
    if (!data || !data.length) {
      tempActiveFacility.note = "";
      tempActiveFacility.selectedFiles = [];
    }
    if (is2x) {
      tempActiveFacility.notes = "";
    }
    tempActiveFacility.clearNote = "";
    tempActiveFacility.isClear = false;
    dispatch(setActiveFacility(tempActiveFacility));
  };

  const updateFacilityNotes = (data) => {
    let tempActiveFacility = { ...activeFacilityObj };
    tempActiveFacility.notes = data;
    dispatch(setActiveFacility(tempActiveFacility));
  };

  const handleFileUpload = (e) => {
    if (e.target.files) {
      let tempActiveFacility = { ...activeFacilityObj };
      let files = Array.prototype.slice.call(e.target.files);
      tempActiveFacility.selectedFiles = files;
      dispatch(setActiveFacility(tempActiveFacility));
    }
  };

  const addNote = (data) => {
    let tempActiveFacility = { ...activeFacilityObj };
    tempActiveFacility.note = data;
    dispatch(setActiveFacility(tempActiveFacility));
  };

  const toggleClearCheckinCheckout = (value) => {
    let tempActiveFacility = { ...activeFacilityObj };
    tempActiveFacility.isClear = value;
    dispatch(setActiveFacility(tempActiveFacility));
  };

  const addClearCheckinCheckoutNote = (value) => {
    let tempActiveFacility = { ...activeFacilityObj };
    tempActiveFacility.clearNote = value;
    dispatch(setActiveFacility(tempActiveFacility));
  };

  const closeModal = () => {
    dispatch(setShowPopover(""));
    dispatch(setAuditKey(""));
    dispatch(setMenuIsOpen({
      checkIn: false,
      checkOut: false,
      stopDay: false,
      spinTime: false,
      checkInTimezone: false, checkOutTimezone: false, spinTimeTimezone: false
    }))
  }

  const checkIfModalIsOpen = () => {
    const fileModal = document.getElementsByClassName("view-atachment-modal") || [];
    if (fileModal && fileModal.length) {
      isModalOpen = true;
    } else {
      isModalOpen = false;
    }
    return isModalOpen;
  }

  const toastModal = document.getElementsByClassName("toast-wrapper") || [];
  let isModalOpen = false;
  if (toastModal && toastModal.length) {
    const displayText = window.getComputedStyle(toastModal[0]) && window.getComputedStyle(toastModal[0]).display;
    if (displayText === "block") {
      isModalOpen = true;
    } else if (displayText === "none") {
      isModalOpen = false;
    }
  }

  const selectedDate = new Date(date);
  selectedDate.setHours(0, 0, 0, 0);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  let boardLoading = scheduleListLoader || onCallScheduleListLoader;

  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.droppableProps}
          className={sectionType !== BACKLOG_SECTION_TYPE ? `${snapshot.isDraggingOver ? "droppable-area-active " : showDropArea ? "droppable-area-inactive droppable-area-border " : " "}` : ""}
        // style={sectionType !== BACKLOG_SECTION_TYPE ? { opacity: showDropArea ? 0.8 : 1, backgroundColor: snapshot.isDraggingOver ? "#ebf6f4" : "" } : {}}
        >
          {facilities.map((facility, facilityIndex) => {
            const {
              account_name: accountName,
              status = "",
              users = [],
              facility_id,
              service_stop_day,
              check_out_count = 0,
              check_in_count = 0,
              street_address_1,
              street_address_2,
              city,
              state,
              zip,
              latitude,
              longitude,
              action: originalAction = [],
              event_has_facility_notes = "",
              event_has_facility_id,
              audit_history = [],
              facility_phone = "",
              status: backlogStatus,
              attachments = []
            } = facility;
            let draggableId = "";
            if (userIndex === undefined) {
              draggableId =
                BACKLOG_SECTION_TYPE +
                "|" +
                serviceIndex +
                "|" +
                zoneIndex +
                "|" +
                routineIndex +
                "|" +
                facilityIndex;
            } else {
              draggableId =
                `user/${blockId}/${uniqueServiceId}` +
                "|" +
                userIndex +
                "|" +
                serviceIndex +
                "|" +
                zoneIndex +
                "|" +
                routineIndex +
                "|" +
                facilityIndex;
            }

            const isDragDisabled = !isDragPermitted(facility, sectionType, eventType);
            let oiginalCheckInObject = originalAction.filter(
              (act) => act.action_type === "Check In"
            )
              ? originalAction.filter(
                (act) => act.action_type === "Check In"
              )[0]
                ? originalAction.filter(
                  (act) => act.action_type === "Check In"
                )[0]
                : {}
              : {};
            let originalCheckOutObject = originalAction.filter(
              (act) => act.action_type === "Check Out"
            )
              ? originalAction.filter(
                (act) => act.action_type === "Check Out"
              )[0]
                ? originalAction.filter(
                  (act) => act.action_type === "Check Out"
                )[0]
                : {}
              : {};

            let originalSpinObject = originalAction.filter(
              (act) => act.action_type === "Spin"
            )
              ? originalAction.filter(
                (act) => act.action_type === "Spin"
              )[0]
                ? originalAction.filter(
                  (act) => act.action_type === "Spin"
                )[0]
                : {}
              : {};

            const { action_datetime: original_datetime = "", timezone: original_timezone = "", preferred_datetime = "", preferred_timezone = {}, action_id } =
              oiginalCheckInObject;
            const { action_datetime: checkout_original_datetime = "", timezone: checkout_original_timezone = "", preferred_datetime: checkout_preferred_datetime = "", preferred_timezone: checkout_preferred_timezone = {},
              action_id: checkout_action_id } =
              originalCheckOutObject;

            const { action_datetime: spin_original_datetime = "", timezone: spin_original_timezone = "", preferred_datetime: spin_preferred_datetime = "", preferred_timezone: spin_preferred_timezone = {},
              action_id: spin_action_id } =
              originalSpinObject;

            const newAction = activeFacilityObj.newAction || [];
            const { notes = event_has_facility_notes ? { label: event_has_facility_notes, value: event_has_facility_notes } : "", note = "", isClear = false, clearNote = "", selectedFiles = [] } = activeFacilityObj;
            let checkInObject = newAction.filter(
              (act) => act.action_type === "Check In"
            )
              ? newAction.filter((act) => act.action_type === "Check In")[0]
                ? newAction.filter((act) => act.action_type === "Check In")[0]
                : {}
              : {};
            let checkOutObject = newAction.filter(
              (act) => act.action_type === "Check Out"
            )
              ? newAction.filter((act) => act.action_type === "Check Out")[0]
                ? newAction.filter((act) => act.action_type === "Check Out")[0]
                : {}
              : {};

            let spinObject = newAction.filter(
              (act) => act.action_type === "Spin"
            )
              ? newAction.filter((act) => act.action_type === "Spin")[0]
                ? newAction.filter((act) => act.action_type === "Spin")[0]
                : {}
              : {};

            const { action_datetime = "", timezone = "" } = checkInObject;
            const { action_datetime: checkout_action_datetime = "", timezone: checkout_timezone = "", action_attachments = [] } =
              checkOutObject;
            const { action_datetime: spin_action_datetime = "", timezone: spin_timezone = "" } =
              spinObject;

            let disableSave = (newAction.length && (newAction.every(act => ((act.action_datetime ? act.action_datetime && act.timezone && act.timezone.value : true) || (act.action_id && act.timezone && act.timezone.value))) || (notes && notes.value)) && (!isClear && note)) || (isClear && clearNote);


            let formattedPhone = "";
            if (facility_phone) {
              formattedPhone = phoneFormat(facility_phone.toString());
            } else {
              formattedPhone = facility_phone;
            }

            let finalAttachments = action_attachments && action_attachments.length ? [...action_attachments, ...attachments] : attachments;
            
            return ((sectionType === BACKLOG_SECTION_TYPE ? !showInactiveFac ? backlogStatus === "ACTIVE" : true : true) &&
              <Draggable
                key={
                  userIndex +
                  "|" +
                  serviceIndex +
                  "|" +
                  zoneIndex +
                  "|" +
                  routineIndex +
                  "|" +
                  facilityIndex
                }
                draggableId={draggableId}
                index={facilityIndex}
                isDragDisabled={isDragDisabled}
              >
                {(provided, snapshot2) => (
                  <Popover
                    key={facility_id}
                    id={"facility-popover" + facility_id}
                    containerClassName='sch-popover-container'
                    isOpen={
                      sectionType === "scheduleBoard"
                        ? showPopover ==
                        `${userIndex}-${blockId}-${facility_id}-${facilityIndex}-${props.event_id}`
                        : false
                    }
                    reposition={true}
                    positions={["left", "right"]}
                    onClickOutside={(e) => {
                      const isFileModalOpen = checkIfModalIsOpen();

                      if (isFileModalOpen) {
                        return;
                      }
                      e.preventDefault();
                      setReRender(!reRender);
                      if (!isModalOpen) {
                        closeModal();
                      }
                      setShowError(false);
                      setShowErrorSpin(false);
                    }}
                    content={({ position, childRect, popoverRect }) =>
                      sectionType === "scheduleBoard" ? (
                        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                          position={position}
                          childRect={childRect}
                          popoverRect={popoverRect}
                          arrowColor={"white"}
                          arrowSize={10}
                          arrowStyle={{ opacity: 1 }}
                          className='popover-arrow-container'
                          arrowClassName='popover-arrow'
                        >
                          <div
                            title='Check In/Check Out'
                            className='sch-popover checkin-popover'
                            onClick={(e) => e.stopPropagation()}
                          >
                            <span
                              className='service-icon2 popoverclose'
                              onClick={() => {
                                document.body.click();
                                setShowError(false);
                                setShowErrorSpin(false);
                              }}
                            >
                              <span className='visually-hidden'>
                                Close Icon
                              </span>
                            </span>
                            <div className='scheduler-wrapper popover-wrapper'>
                              {props.event_id && (
                                <div className='sub-serivce-type'>
                                  <span className='service-icon'></span>
                                  <div>
                                    <h3 className='title roboto-regular'>
                                      {accountName}
                                    </h3>
                                    <p>
                                      {street_address_1} {street_address_2}
                                    </p>
                                    <p>
                                      {city} {state ? `, ${state}` : ""}{" "}
                                      {zip || ""}
                                    </p>
                                  </div>
                                </div>
                              )}

                              {props.event_id ? (
                                <div className='schedule-accordian-wrapper accordion aha-checkin-accordion-wrapper '>
                                  <div className='choose-time-wrapper flex-wrap align-items-center justify-content-start'>
                                    <div className='d-flex flex-wrap sch-timezone-wrapper'>
                                      <div className='d-flex'>
                                        <InputGroup className='mb-4 check-in-select'>
                                          <Form.Label htmlFor='check-in-time'>
                                            Check In Time
                                          </Form.Label>
                                          <div className='position-relative w-100'>
                                            <CustomDropdown
                                              id='check-in-time'
                                              options={constructTimeOptions()}
                                              value={action_datetime}
                                              isDisabled={
                                                !hasRouteStarted ||
                                                !latitude ||
                                                !longitude ||
                                                !isExecutive
                                              }
                                              placeholder='Choose'
                                              className='sch-schedule-dropdown sch-schedule-select__control'
                                              menuIsOpen={
                                                menuIsOpen.checkIn || false
                                              }
                                              setMenuIsOpen={(val) =>
                                                dispatch(
                                                  setMenuIsOpen({
                                                    checkIn: val,
                                                  })
                                                )
                                              }
                                              wrapperRef={checkInRef}
                                              onChange={(e) => {
                                                const showError = compareTimes(
                                                  date,
                                                  e.value,
                                                  false,
                                                  checkout_action_datetime
                                                    ? checkout_action_datetime.label ||
                                                    checkout_original_datetime ||
                                                    ""
                                                    : checkout_original_datetime,
                                                  checkout_action_datetime &&
                                                    checkout_action_datetime.label
                                                    ? false
                                                    : checkout_original_datetime
                                                      ? true
                                                      : false,
                                                  timezone,
                                                  checkout_timezone,
                                                  "start"
                                                );
                                                const tempAction = [
                                                  ...newAction,
                                                ];
                                                if (
                                                  tempAction.some(
                                                    (act) =>
                                                      act.action_type ===
                                                      "Check In"
                                                  )
                                                ) {
                                                  tempAction.forEach(
                                                    (act, index) => {
                                                      if (
                                                        act.action_type ===
                                                        "Check In"
                                                      ) {
                                                        tempAction[index] = {
                                                          ...tempAction[index],
                                                          action_datetime:
                                                            !showError ? e : {},
                                                          action_id:
                                                            oiginalCheckInObject.action_id ||
                                                            0,
                                                        };
                                                      }
                                                    }
                                                  );
                                                } else {
                                                  tempAction.push({
                                                    ...checkInObject,
                                                    action_datetime: !showError
                                                      ? e
                                                      : {},
                                                    action_type: "Check In",
                                                    action_id: !showError
                                                      ? oiginalCheckInObject.action_id ||
                                                      0
                                                      : 0,
                                                  });
                                                }
                                                updateCheckinCheckoutData(
                                                  tempAction
                                                );
                                                setShowError(showError);
                                              }}
                                            />
                                            <span className='check-in'>
                                              <span className='visually-hidden'>
                                                Check in icon
                                              </span>
                                            </span>
                                          </div>
                                          <span className='d-block roboto-bold form-timezone-text'>
                                            {original_datetime
                                              ? convertToMMDDYYYY(
                                                original_datetime,
                                                original_timezone,
                                                "Actual",
                                                true
                                              )
                                              : ""}
                                          </span>
                                          <span className='d-block roboto-bold form-timezone-text'>
                                            {preferred_datetime
                                              ? convertToMMDDYYYY(
                                                preferred_datetime,
                                                preferred_timezone,
                                                "Preferred",
                                                true
                                              )
                                              : ""}
                                          </span>
                                        </InputGroup>
                                        <InputGroup className='mb-4 check-in-timezone-select'>
                                          <Form.Label htmlFor='check-in-timezone'>
                                            Timezone
                                          </Form.Label>
                                          <div className='position-relative w-100'>
                                            <CustomDropdown
                                              id='check-in-timezone'
                                              options={timezone_ids}
                                              value={timezone}
                                              showLabel={true}
                                              isDisabled={
                                                !hasRouteStarted ||
                                                !latitude ||
                                                !longitude ||
                                                !isExecutive
                                                // (!action_id && !action_datetime)
                                              }
                                              placeholder='Tz'
                                              className='sch-schedule-dropdown sch-schedule-select__control'
                                              menuIsOpen={
                                                menuIsOpen.checkInTimezone ||
                                                false
                                              }
                                              setMenuIsOpen={(val) =>
                                                dispatch(
                                                  setMenuIsOpen({
                                                    checkInTimezone: val,
                                                  })
                                                )
                                              }
                                              wrapperRef={checkInTimezoneRef}
                                              onChange={(e) => {
                                                const showError = compareTimes(
                                                  date,
                                                  action_datetime
                                                    ? action_datetime.label ||
                                                    original_datetime ||
                                                    ""
                                                    : original_datetime,
                                                  action_datetime &&
                                                    action_datetime.label
                                                    ? false
                                                    : original_datetime
                                                      ? true
                                                      : false,
                                                  checkout_action_datetime
                                                    ? checkout_action_datetime.label ||
                                                    checkout_original_datetime ||
                                                    ""
                                                    : checkout_original_datetime,
                                                  checkout_action_datetime &&
                                                    checkout_action_datetime.label
                                                    ? false
                                                    : checkout_original_datetime
                                                      ? true
                                                      : false,
                                                  e,
                                                  checkout_timezone,
                                                  "start"
                                                );
                                                const tempAction = [
                                                  ...newAction,
                                                ];
                                                if (
                                                  tempAction.some(
                                                    (act) =>
                                                      act.action_type ===
                                                      "Check In"
                                                  )
                                                ) {
                                                  tempAction.forEach(
                                                    (act, index) => {
                                                      if (
                                                        act.action_type ===
                                                        "Check In") {
                                                        tempAction[index] = {
                                                          ...tempAction[index],
                                                          timezone: !showError ? e : "",
                                                        };
                                                      }
                                                    }
                                                  );
                                                } else {
                                                  tempAction.push({
                                                    ...checkInObject,
                                                    timezone: !showError ? e : {},
                                                    action_type: "Check In",
                                                    action_id: 0,
                                                  });
                                                }
                                                updateCheckinCheckoutData(
                                                  tempAction
                                                );
                                                setShowError(showError);
                                              }}
                                            />
                                            {original_datetime &&
                                              preferred_datetime && (
                                                <>
                                                  <span className='invisible d-block roboto-bold form-timezone-text'>
                                                    timezone
                                                  </span>
                                                  <span className='invisible d-block roboto-bold form-timezone-text'>
                                                    timezone
                                                  </span>
                                                </>
                                              )}
                                          </div>
                                        </InputGroup>
                                      </div>

                                      <div className='d-flex sch-checkout-wrapper align-items-start'>
                                        <InputGroup className='mb-4 check-in-select ms-4 ps-3'>
                                          <Form.Label htmlFor='check-out-time'>
                                            Check Out Time
                                          </Form.Label>
                                          <div className='position-relative w-100'>
                                            <CustomDropdown
                                              placeholder='Choose'
                                              name='schedule-end-time'
                                              id='check-out-time'
                                              className='sch-schedule-dropdown sch-schedule-select__control'
                                              value={checkout_action_datetime}
                                              options={constructTimeOptions()}
                                              menuIsOpen={
                                                menuIsOpen.checkOut || false
                                              }
                                              setMenuIsOpen={(val) =>
                                                dispatch(
                                                  setMenuIsOpen({
                                                    checkOut: val,
                                                  })
                                                )
                                              }
                                              wrapperRef={checkOutRef}
                                              onChange={(e) => {
                                                const showError = compareTimes(
                                                  date,
                                                  e.value,
                                                  false,
                                                  action_datetime
                                                    ? action_datetime.label ||
                                                    original_datetime ||
                                                    ""
                                                    : original_datetime,
                                                  action_datetime &&
                                                    action_datetime.label
                                                    ? false
                                                    : original_datetime
                                                      ? true
                                                      : false,
                                                  checkout_timezone,
                                                  timezone,
                                                  "end"
                                                );
                                                const showErrorWithSpin =
                                                  compareTimes(
                                                    date,
                                                    e.value,
                                                    false,
                                                    spin_action_datetime
                                                      ? spin_action_datetime.label ||
                                                      spin_original_datetime ||
                                                      ""
                                                      : spin_original_datetime,
                                                    spin_action_datetime &&
                                                      spin_action_datetime.label
                                                      ? false
                                                      : spin_original_datetime
                                                        ? true
                                                        : false,
                                                    checkout_timezone,
                                                    spin_timezone,
                                                    "start"
                                                  );

                                                const tempAction = [
                                                  ...newAction,
                                                ];
                                                if (
                                                  tempAction.some(
                                                    (act) =>
                                                      act.action_type ===
                                                      "Check Out"
                                                  )
                                                ) {
                                                  tempAction.forEach(
                                                    (act, index) => {
                                                      if (
                                                        act.action_type ===
                                                        "Check Out"
                                                      ) {
                                                        tempAction[index] = {
                                                          ...tempAction[index],
                                                          action_datetime:
                                                            !showError &&
                                                              !showErrorWithSpin
                                                              ? e
                                                              : {},
                                                          action_id:
                                                            !showError &&
                                                              !showErrorWithSpin
                                                              ? originalCheckOutObject.action_id ||
                                                              0
                                                              : 0,
                                                        };
                                                      }
                                                    }
                                                  );
                                                } else {
                                                  tempAction.push({
                                                    ...checkOutObject,
                                                    action_datetime:
                                                      !showError &&
                                                        !showErrorWithSpin
                                                        ? e
                                                        : {},
                                                    action_type: "Check Out",
                                                    action_id:
                                                      !showError &&
                                                        !showErrorWithSpin
                                                        ? originalCheckOutObject.action_id ||
                                                        0
                                                        : 0,
                                                  });
                                                }
                                                updateCheckinCheckoutData(
                                                  tempAction
                                                );
                                                setShowError(showError);
                                                setShowErrorSpin(
                                                  showErrorWithSpin
                                                );
                                              }}
                                              isDisabled={
                                                !hasRouteStarted ||
                                                !latitude ||
                                                !longitude ||
                                                !isExecutive
                                              }
                                            />
                                            <span className='check-out'>
                                              <span className='visually-hidden'>
                                                Check out icon
                                              </span>
                                            </span>
                                          </div>
                                          {(original_datetime && !checkout_original_datetime) &&
                                            (preferred_datetime && !checkout_preferred_datetime) && (
                                              <>
                                                <span className='invisible d-block w-100 roboto-bold form-timezone-text'>
                                                  timezone
                                                </span>
                                                <span className='invisible d-block w-100 roboto-bold form-timezone-text'>
                                                  timezone
                                                </span>

                                              </>
                                            )}
                                          <span className='d-block roboto-bold form-timezone-text'>
                                            {checkout_original_datetime
                                              ? convertToMMDDYYYY(
                                                checkout_original_datetime,
                                                checkout_original_timezone,
                                                "Actual",
                                                true
                                              )
                                              : ""}
                                          </span>
                                          <span className='d-block roboto-bold form-timezone-text'>
                                            {checkout_preferred_datetime
                                              ? convertToMMDDYYYY(
                                                checkout_preferred_datetime,
                                                checkout_preferred_timezone,
                                                "Preferred",
                                                true
                                              )
                                              : ""}
                                          </span>
                                          {showError && (
                                            <span className='start-routine-warning checkout-error'>
                                              Check In Time cannot exceed Check
                                              Out Time
                                            </span>
                                          )}
                                        </InputGroup>
                                        <InputGroup className='mb-4 check-in-timezone-select'>
                                          <Form.Label htmlFor='check-out-timezone'>
                                            Timezone
                                          </Form.Label>
                                          <div className='position-relative w-100'>
                                            <CustomDropdown
                                              placeholder='Tz'
                                              name='schedule-end-time'
                                              id='check-out-timezone'
                                              showLabel={true}
                                              className='sch-schedule-dropdown sch-schedule-select__control'
                                              value={checkout_timezone}
                                              options={timezone_ids}
                                              menuIsOpen={
                                                menuIsOpen.checkOutTimezone ||
                                                false
                                              }
                                              setMenuIsOpen={(val) =>
                                                dispatch(
                                                  setMenuIsOpen({
                                                    checkOutTimezone: val,
                                                  })
                                                )
                                              }
                                              wrapperRef={checkOutTimezoneRef}
                                              onChange={(e) => {
                                                const showError = compareTimes(
                                                  date,
                                                  checkout_action_datetime
                                                    ? checkout_action_datetime.label ||
                                                    checkout_original_datetime ||
                                                    ""
                                                    : checkout_original_datetime,
                                                  checkout_action_datetime &&
                                                    checkout_action_datetime.label
                                                    ? false
                                                    : checkout_original_datetime
                                                      ? true
                                                      : false,
                                                  action_datetime
                                                    ? action_datetime.label ||
                                                    original_datetime ||
                                                    ""
                                                    : original_datetime,
                                                  action_datetime &&
                                                    action_datetime.label
                                                    ? false
                                                    : original_datetime
                                                      ? true
                                                      : false,
                                                  e,
                                                  timezone,
                                                  "end"
                                                );
                                                const showErrorWithSpin =
                                                  compareTimes(
                                                    date,
                                                    checkout_action_datetime
                                                      ? checkout_action_datetime.label ||
                                                      checkout_original_datetime ||
                                                      ""
                                                      : checkout_original_datetime,
                                                    checkout_action_datetime &&
                                                      checkout_action_datetime.label
                                                      ? false
                                                      : checkout_original_datetime
                                                        ? true
                                                        : false,
                                                    spin_action_datetime
                                                      ? spin_action_datetime.label ||
                                                      spin_original_datetime ||
                                                      ""
                                                      : spin_original_datetime,
                                                    spin_action_datetime &&
                                                      spin_action_datetime.label
                                                      ? false
                                                      : spin_original_datetime
                                                        ? true
                                                        : false,
                                                    e,
                                                    spin_timezone,
                                                    "start"
                                                  );
                                                const tempAction = [
                                                  ...newAction,
                                                ];
                                                if (
                                                  tempAction.some(
                                                    (act) =>
                                                      act.action_type ===
                                                      "Check Out"
                                                  )
                                                ) {
                                                  tempAction.forEach(
                                                    (act, index) => {
                                                      if (
                                                        act.action_type ===
                                                        "Check Out") {
                                                        tempAction[index] = {
                                                          ...tempAction[index],
                                                          timezone: !showError && !showErrorWithSpin ? e : "",
                                                        };
                                                      }
                                                    }
                                                  );
                                                } else {
                                                  tempAction.push({
                                                    ...checkOutObject,
                                                    timezone: !showError && !showErrorWithSpin ? e : "",
                                                    action_type: "Check Out",
                                                    action_id: 0,
                                                  });
                                                }
                                                updateCheckinCheckoutData(
                                                  tempAction
                                                );
                                                setShowError(showError);
                                                setShowErrorSpin(showErrorWithSpin)
                                              }}
                                              isDisabled={
                                                !hasRouteStarted ||
                                                !latitude ||
                                                !longitude ||
                                                !isExecutive
                                                // (!checkout_action_id &&
                                                //   !checkout_action_datetime)
                                              }
                                            />
                                            {original_datetime &&
                                              preferred_datetime && (
                                                <>
                                                  <span className='invisible d-block roboto-bold form-timezone-text'>
                                                    timezone
                                                  </span>
                                                  <span className='invisible d-block roboto-bold form-timezone-text'>
                                                    timezone
                                                  </span>
                                                </>
                                              )}
                                          </div>
                                        </InputGroup>
                                      </div>
                                      <div
                                        className={`d-flex sch-checkout-wrapper ${service_stop_day == 2 &&
                                          routineIndex !== "nonserviceday"
                                          ? "spinwithservicestop"
                                          : ""
                                          }`}
                                      >
                                        <InputGroup className='mb-4 check-in-select'>
                                          <Form.Label htmlFor='spin-time'>
                                            Spin Time
                                          </Form.Label>
                                          <div className='position-relative w-100'>
                                            <CustomDropdown
                                              placeholder='Choose'
                                              name='schedule-end-time'
                                              id='spin-time'
                                              className='sch-schedule-dropdown sch-schedule-select__control'
                                              value={spin_action_datetime}
                                              options={constructTimeOptions()}
                                              menuIsOpen={
                                                menuIsOpen.spinTime || false
                                              }
                                              setMenuIsOpen={(val) =>
                                                dispatch(
                                                  setMenuIsOpen({
                                                    spinTime: val,
                                                  })
                                                )
                                              }
                                              wrapperRef={spinTimeRef}
                                              onChange={(e) => {
                                                const showErrorSpin =
                                                  compareTimes(
                                                    date,
                                                    e.value,
                                                    false,
                                                    checkout_action_datetime
                                                      ? checkout_action_datetime.label ||
                                                      checkout_original_datetime ||
                                                      ""
                                                      : checkout_original_datetime,
                                                    checkout_action_datetime &&
                                                      checkout_action_datetime.label
                                                      ? false
                                                      : checkout_original_datetime
                                                        ? true
                                                        : false,
                                                    spin_timezone,
                                                    checkout_timezone,
                                                    "end"
                                                  );

                                                const tempAction = [
                                                  ...newAction,
                                                ];
                                                if (
                                                  tempAction.some(
                                                    (act) =>
                                                      act.action_type === "Spin"
                                                  )
                                                ) {
                                                  tempAction.forEach(
                                                    (act, index) => {
                                                      if (
                                                        act.action_type ===
                                                        "Spin"
                                                      ) {
                                                        tempAction[index] = {
                                                          ...tempAction[index],
                                                          action_datetime:
                                                            !showErrorSpin
                                                              ? e
                                                              : {},
                                                          action_id:
                                                            !showErrorSpin
                                                              ? originalSpinObject.action_id ||
                                                              0
                                                              : 0,
                                                        };
                                                      }
                                                    }
                                                  );
                                                } else {
                                                  tempAction.push({
                                                    ...spinObject,
                                                    action_datetime:
                                                      !showErrorSpin ? e : {},
                                                    action_type: "Spin",
                                                    action_id: !showErrorSpin
                                                      ? originalSpinObject.action_id ||
                                                      0
                                                      : 0,
                                                  });
                                                }
                                                updateCheckinCheckoutData(
                                                  tempAction
                                                );
                                                setShowErrorSpin(showErrorSpin);
                                              }}
                                              isDisabled={
                                                !hasRouteStarted ||
                                                !latitude ||
                                                !longitude ||
                                                !isExecutive
                                              }
                                            />
                                            <span className={`spin check-out `}>
                                              <span className='visually-hidden'>
                                                Spin icon
                                              </span>
                                            </span>
                                          </div>
                                          <span className='d-block roboto-bold form-timezone-text'>
                                            {spin_original_datetime
                                              ? convertToMMDDYYYY(
                                                spin_original_datetime,
                                                spin_original_timezone,
                                                "Actual",
                                                true
                                              )
                                              : ""}
                                          </span>
                                          <span className='d-block roboto-bold form-timezone-text'>
                                            {spin_preferred_datetime
                                              ? convertToMMDDYYYY(
                                                spin_preferred_datetime,
                                                spin_preferred_timezone,
                                                "Preferred",
                                                true
                                              )
                                              : ""}
                                          </span>
                                          {showErrorSpin && (
                                            <span className='start-routine-warning checkout-error'>
                                              Check Out Time cannot exceed Spin
                                              Time
                                            </span>
                                          )}
                                        </InputGroup>
                                        <InputGroup className='mb-4 check-in-timezone-select'>
                                          <Form.Label htmlFor='spin-timezone'>
                                            Timezone
                                          </Form.Label>
                                          <div className='position-relative w-100'>
                                            <CustomDropdown
                                              placeholder='Tz'
                                              name='schedule-end-time'
                                              id='spin-timezone'
                                              showLabel={true}
                                              className='sch-schedule-dropdown sch-schedule-select__control'
                                              value={spin_timezone}
                                              options={timezone_ids}
                                              menuIsOpen={
                                                menuIsOpen.spinTimeTimezone ||
                                                false
                                              }
                                              setMenuIsOpen={(val) =>
                                                dispatch(
                                                  setMenuIsOpen({
                                                    spinTimeTimezone: val,
                                                  })
                                                )
                                              }
                                              wrapperRef={spinTimeTimezoneRef}
                                              onChange={(e) => {
                                                const showErrorSpin =
                                                  compareTimes(
                                                    date,
                                                    spin_action_datetime
                                                      ? spin_action_datetime.label ||
                                                      spin_original_datetime ||
                                                      ""
                                                      : spin_original_datetime,
                                                    spin_action_datetime &&
                                                      spin_action_datetime.label
                                                      ? false
                                                      : spin_original_datetime
                                                        ? true
                                                        : false,
                                                    checkout_action_datetime
                                                      ? checkout_action_datetime.label ||
                                                      checkout_original_datetime ||
                                                      ""
                                                      : checkout_original_datetime,
                                                    checkout_action_datetime &&
                                                      checkout_action_datetime.label
                                                      ? false
                                                      : checkout_original_datetime
                                                        ? true
                                                        : false,
                                                    e,
                                                    checkout_timezone,
                                                    "end"
                                                  );
                                                const tempAction = [
                                                  ...newAction,
                                                ];
                                                if (
                                                  tempAction.some(
                                                    (act) =>
                                                      act.action_type === "Spin"
                                                  )
                                                ) {
                                                  tempAction.forEach(
                                                    (act, index) => {
                                                      if (
                                                        act.action_type ===
                                                        "Spin"
                                                      ) {
                                                        tempAction[index] = {
                                                          ...tempAction[index],
                                                          timezone: !showErrorSpin ? e : "",
                                                        };
                                                      }
                                                    }
                                                  );
                                                } else {
                                                  tempAction.push({
                                                    ...spinObject,
                                                    timezone: !showErrorSpin ? e : "",
                                                    action_type: "Spin",
                                                    action_id: 0,
                                                  });
                                                }
                                                updateCheckinCheckoutData(
                                                  tempAction
                                                );
                                                setShowErrorSpin(showErrorSpin)
                                              }}
                                              isDisabled={
                                                !hasRouteStarted ||
                                                !latitude ||
                                                !longitude ||
                                                !isExecutive
                                                // (!spin_action_id &&
                                                //   !spin_action_datetime)
                                              }
                                            />
                                            {spin_original_datetime &&
                                              spin_preferred_datetime && (
                                                <>
                                                  <span className='invisible d-block roboto-bold form-timezone-text'>
                                                    timezone
                                                  </span>
                                                  <span className='invisible d-block roboto-bold form-timezone-text'>
                                                    timezone
                                                  </span>
                                                </>
                                              )}
                                          </div>
                                        </InputGroup>
                                      </div>
                                      <div
                                        className={`d-flex sch-checkout-wrapper ${service_stop_day == 2 &&
                                          routineIndex !== "nonserviceday"
                                          ? "spinwithservicestop"
                                          : ""
                                          }`}
                                      >
                                        <div className={` d-flex flex-wrap do-attachment-wrapper requi-attachment-wrapper aha-modalform-group mb-4 check-in-select  ${spin_original_datetime && spin_preferred_datetime  ? "with-datetime" : ""}`}>
                                          <label className="form-label" htmlFor="choose-file">
                                            Requisition Image Capture
                                          </label>
                                          <input
                                            accept="image/*"
                                            className="facility-attachment-file"
                                            id="choose-file"
                                            type="file"
                                            onChange={handleFileUpload}
                                            disabled={!hasRouteStarted || !isExecutive}
                                            onClick={(event) => {
                                              event.target.value = null;
                                            }}
                                            multiple
                                          />
                                          <p className="do-selected-file-facility">{selectedFiles &&
                                            selectedFiles.length > 0 ? selectedFiles.length === 1 ?
                                            selectedFiles[0].name : `${selectedFiles.length} files` : ""}</p>
                                      {spin_original_datetime &&
                                              spin_preferred_datetime && (
                                                <>
                                                  <span className='invisible d-block roboto-bold form-timezone-text'>
                                                    requisition image capture block
                                                  </span>
                                                </>
                                              )}
                                        </div>
                                      </div>
                                      <div
                                        className={`sch-checkout-wrapper ${service_stop_day == 2 &&
                                          routineIndex !== "nonserviceday"
                                          ? "spinwithservicestop"
                                          : ""
                                          }`}
                                      >
                                        {finalAttachments && finalAttachments.map((file, idx) => {
                                          return (
                                            <div
                                              className="view-attachment hh"
                                              key={idx}
                                              data-testid="requisitions-id"
                                            >
                                              <p key={file.id}>
                                                <span className="file-name">{file.file_name}</span>
                                                <span
                                                  className="view-btn"
                                                  onClick={() => viewAttachment(file)}
                                                >
                                                  View Attachment
                                                </span>
                                              </p>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                    {service_stop_day == 2 &&
                                      routineIndex !== "nonserviceday" && (
                                        <div className='d-flex'>
                                          <InputGroup className='mb-3'>
                                            <Form.Label htmlFor='check-out-time'>
                                              Service Stop Day
                                            </Form.Label>
                                            <div className='position-relative w-100'>
                                              <CustomDropdown
                                                placeholder='Choose'
                                                name='schedule-end-time'
                                                id='service-stop-day'
                                                className='sch-schedule-dropdown sch-schedule-select__control'
                                                value={notes}
                                                wrapperRef={stopDayRef}
                                                menuIsOpen={
                                                  menuIsOpen.stopDay || false
                                                }
                                                setMenuIsOpen={(val) =>
                                                  dispatch(
                                                    setMenuIsOpen({
                                                      stopDay: val,
                                                    })
                                                  )
                                                }
                                                onChange={(e) =>
                                                  updateFacilityNotes(e)
                                                }
                                                options={[
                                                  { label: "1", value: "1" },
                                                  { label: "2", value: "2" },
                                                ]}
                                                isDisabled={
                                                  !hasRouteStarted ||
                                                  !isExecutive
                                                }
                                              />
                                            </div>
                                          </InputGroup>
                                        </div>
                                      )}
                                    {canClearCheckinCheckout &&
                                      original_datetime && (
                                        <div className='w-100'>
                                          <div className='form-check me-3 mb-3 mt-1'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              disabled={
                                                !isExecutive || !hasRouteStarted
                                              }
                                              value={isClear}
                                              checked={isClear}
                                              onChange={(e) => {
                                                toggleClearCheckinCheckout(
                                                  e.target.checked
                                                );
                                              }}
                                              id='clearcheckin/out'
                                            />
                                            <label
                                              className='form-check-label pt-1'
                                              htmlFor='clearcheckin/out'
                                            >
                                              Clear Check In/Out & Spin
                                            </label>
                                          </div>
                                          <InputGroup className='mb-1'>
                                            <Form.Label
                                              htmlFor='check-in-check-out-time'
                                              style={{ minHeight: 20 }}
                                            >
                                              Checkin/Checkout Removal Comments
                                            </Form.Label>
                                            <div className='position-relative w-100 aha-modalform-group'>
                                              <textarea
                                                value={clearNote}
                                                onChange={(e) =>
                                                  addClearCheckinCheckoutNote(
                                                    e.target.value
                                                  )
                                                }
                                                disabled={
                                                  !isExecutive ||
                                                  !hasRouteStarted
                                                }
                                                id='check-in-check-out-time'
                                              />
                                            </div>
                                            <p
                                              data-testid='error-text-id'
                                              className='error-message-text required'
                                            >
                                              Required
                                              <span className='error'>*</span>
                                            </p>
                                          </InputGroup>
                                        </div>
                                      )}
                                  </div>
                                  <div className='sch-notes-wrapper '>
                                    <InputGroup className='mb-1'>
                                      <Form.Label htmlFor='check-out-time'>
                                        Notes
                                      </Form.Label>
                                      <div className='position-relative w-100 aha-modalform-group'>
                                        <textarea
                                          value={note}
                                          onChange={(e) =>
                                            addNote(e.target.value)
                                          }
                                          disabled={
                                            !isExecutive || !hasRouteStarted
                                          }
                                        />
                                      </div>
                                    </InputGroup>
                                    <Accordion
                                      activeKey={auditKey}
                                      onSelect={(val) => {
                                        dispatch(setAuditKey(val));
                                      }}
                                    >
                                      <Accordion.Item eventKey='0'>
                                        <Accordion.Header className='audit-log-header'>
                                          Audit Log
                                          <span className='al-arrow-down'></span>{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          {/* For checkin */}
                                          <ul className='audit-list-wrapper justify-content-between flex-wrap'>
                                            {audit_history.length > 0 ? (
                                              audit_history.map((act, idx) => {
                                                const {
                                                  user = {},
                                                  activity,
                                                  datetime = "",
                                                  notes = "",
                                                  type,
                                                  abbreviation,
                                                } = act;
                                                return (
                                                  <li key={idx}>
                                                    <span className='al-activity roboto-bold'>
                                                      {moment(datetime).format(
                                                        "MM/DD/YYYY HH:mm:ss"
                                                      )}{" "}
                                                      {abbreviation || ""}
                                                    </span>
                                                    <span className='d-flex'>
                                                      {" "}
                                                      <span className='al-activity pe-1'>
                                                        {activity}{" "}
                                                        {user
                                                          ? ` By: @${user.username}`
                                                          : ""}
                                                        <i>
                                                          {type
                                                            ? ` (${type})`
                                                            : ""}
                                                        </i>
                                                      </span>
                                                      <span className='al-activity'></span>{" "}
                                                    </span>
                                                    {notes && (
                                                      <span className='al-activity'>
                                                        Notes: {notes}
                                                      </span>
                                                    )}
                                                  </li>
                                                );
                                              })
                                            ) : (
                                              <p className='font-size-12 grey-color'>
                                                No logs to show
                                              </p>
                                            )}
                                          </ul>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <span className={"loader ad-loader d-block"}>
                                    <span className='visually-hidden'>
                                      loader icon
                                    </span>
                                  </span>
                                  <span className='popover-message'>
                                    Please wait while the page loads. If this
                                    takes longer than usual, please refresh your
                                    browser
                                  </span>
                                </div>
                              )}

                              {props.event_id && !hasRouteStarted && (
                                <span className='start-routine-warning'>
                                  Note: You need to start the daily routine
                                  route before you try to check-in
                                </span>
                              )}

                              {props.event_id && (
                                <div className='d-flex checkin-btn-wrapper justify-content-end'>
                                  <Button
                                    className='primary-btn primary-btn-outline outline'
                                    // disabled={service_stop_day == 2 ? !notes : !latitude || !longitude}
                                    onClick={() => {
                                      updateCheckinCheckoutData(
                                        [],
                                        service_stop_day == 2
                                      );
                                      setShowError(false);
                                      setShowErrorSpin(false);
                                    }}
                                  >
                                    clear
                                  </Button>
                                  <Button
                                    className='primary-btn primary-btn-outline'
                                    disabled={
                                      !disableSave ||
                                      !hasRouteStarted ||
                                      showError ||
                                      showErrorSpin ||
                                      disableSaveButtonAfterApiCall
                                    }
                                    onClick={() =>
                                      updateCheckinCheckout(
                                        longitude,
                                        latitude,
                                        facility_id,
                                        facilityIndex
                                      )
                                    }
                                  >
                                    Save
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </ArrowContainer>
                      ) : (
                        <div />
                      )
                    }
                  >
                    <div>
                      <div
                        className='e-card2'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={(e) => {
                          e.stopPropagation();
                          !event_has_facility_id &&
                            getScheduleBoardData &&
                            getScheduleBoardData();
                          togglePopOver(
                            facility_id,
                            blockId,
                            facilityIndex,
                            facility,
                            userIndex
                          );
                          if (!props.event_id) {
                            setTimeout(() => {
                              document.body.click();
                            }, 5000);
                          }
                        }}
                      >
                        <div
                          className={`service-locations ${getClassNameByStatus(
                            sectionType,
                            eventType,
                            status,
                            facility
                          )}`}
                          style={
                            sectionType === BACKLOG_SECTION_TYPE &&
                              backlogStatus === INACTIVE_STATUS
                              ? { cursor: "not-allowed" }
                              : {}
                          }
                        >
                          <span
                            className='bg-user-icon'
                            title={
                              users
                                ? users.length > 0
                                  ? `Phleb: ${users[0].name
                                    ? users[0].name.replace(
                                      /(^\w{1})|(\s+\w{1})/g,
                                      (letter) => letter.toUpperCase()
                                    )
                                    : ""
                                  }${users[0].reporting_manager_name
                                    ? `\nReporting Manager: ${users[0].reporting_manager_name.replace(
                                      /(^\w{1})|(\s+\w{1})/g,
                                      (letter) => letter.toUpperCase()
                                    )}`
                                    : ""
                                  }
                        ${users[1]
                                    ? `\nPhleb: ${users[1].name
                                      ? users[1].name.replace(
                                        /(^\w{1})|(\s+\w{1})/g,
                                        (letter) => letter.toUpperCase()
                                      )
                                      : ""
                                    }${users[1].reporting_manager_name
                                      ? `\nReporting Manager: ${users[1].reporting_manager_name.replace(
                                        /(^\w{1})|(\s+\w{1})/g,
                                        (letter) => letter.toUpperCase()
                                      )}`
                                      : ""
                                    }`
                                    : ``
                                  }`
                                  : ""
                                : ""
                            }
                          >
                            <span className='visually-hidden'>
                              Schedule 2x user icon
                            </span>
                          </span>
                          {(!status || status === "ACTIVE") &&
                            props.sectionType === "scheduleBoard" &&
                            (eventType === SURGE_TYPE
                              ? canDeleteSurgeFacility
                              : eventType === NON_SERVICE_TYPE
                                ? canDeleteNonServiceFacility
                                : canDeleteFacility) && (
                              <span
                                className='service-icon2'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  !boardLoading &&
                                    props.handleDelete(
                                      userIndex,
                                      uniqueServiceId,
                                      zoneIndex,
                                      routineIndex,
                                      facilityIndex,
                                      facility_id,
                                      event_has_facility_id,
                                      eventType
                                    );
                                }}
                                style={
                                  boardLoading ? { pointerEvents: "none" } : {}
                                }
                              >
                                <span className='visually-hidden'>
                                  Schedule Board
                                </span>
                              </span>
                            )}
                          {sectionType === BACKLOG_SECTION_TYPE ? (
                            service_stop_day == 1 ? (
                              users.length > 0 ? (
                                <React.Fragment>
                                  <Avatar
                                    color={
                                      users[0].icon_color_code
                                        ? `#${users[0].icon_color_code}`
                                        : "#333745"
                                    }
                                    name={users[0].name}
                                    maxInitials={2}
                                    size={35}
                                    round='50px'
                                    className='sch-avatar-wrapper'
                                    title={`Phleb: ${users[0].name
                                      ? users[0].name.replace(
                                        /(^\w{1})|(\s+\w{1})/g,
                                        (letter) => letter.toUpperCase()
                                      )
                                      : ""
                                      }\n${users[0].reporting_manager_name
                                        ? `Reporting Manager: ${users[0].reporting_manager_name.replace(
                                          /(^\w{1})|(\s+\w{1})/g,
                                          (letter) => letter.toUpperCase()
                                        )}`
                                        : ""
                                      }`}
                                  />
                                  <p className='title'>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip
                                          id='tooltip-disabled'
                                          className='service-location-tooltip'
                                        >
                                          <div>
                                            <div>
                                              Parent Account Number:{" "}
                                              {facility.account_number}
                                            </div>
                                            <div className='mb-10'>
                                              <strong>
                                                Parent Account Name:{" "}
                                                {facility.account_name}
                                              </strong>
                                            </div>
                                            <div className='tooltip-adress-group'>
                                              <span>
                                                Address:{" "}
                                                {facility.street_address_1}
                                              </span>
                                              <span>
                                                {facility.street_address_2}
                                              </span>
                                            </div>

                                            <div>
                                              {facility.city}, {facility.state},{" "}
                                              {facility.zip}
                                            </div>
                                            {formattedPhone && (
                                              <div className='mb-10'>
                                                Facility Contact Phone Number:{" "}
                                                {formattedPhone}{" "}
                                                {facility.facility_phone_number_ext
                                                  ? ` Ext. ${facility.facility_phone_number_ext}`
                                                  : ""}
                                              </div>
                                            )}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <span className='d-inline-block ah-sch-account-name'>
                                        {accountName}
                                      </span>
                                    </OverlayTrigger>
                                  </p>
                                </React.Fragment>
                              ) : (
                                <p className='title'>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip
                                        id='tooltip-disabled'
                                        className='service-location-tooltip'
                                      >
                                        <div>
                                          <div>
                                            Parent Account Number:{" "}
                                            {facility.account_number}
                                          </div>
                                          <div className='mb-10'>
                                            <strong>
                                              ParentAccount Name:{" "}
                                              {facility.account_name}
                                            </strong>
                                          </div>
                                          <div className='tooltip-adress-group'>
                                            <span>
                                              Address:{" "}
                                              {facility.street_address_1}
                                            </span>
                                            <span>
                                              {facility.street_address_2}
                                            </span>
                                          </div>

                                          <div>
                                            {facility.city}, {facility.state},{" "}
                                            {facility.zip}
                                          </div>
                                          {formattedPhone && (
                                            <div className='mb-10'>
                                              Facility Contact Phone Number:{" "}
                                              {formattedPhone}{" "}
                                              {facility.facility_phone_number_ext
                                                ? ` Ext. ${facility.facility_phone_number_ext}`
                                                : ""}
                                            </div>
                                          )}
                                        </div>
                                      </Tooltip>
                                    }
                                  >
                                    <span className='d-inline-block ah-sch-account-name'>
                                      {accountName}
                                    </span>
                                  </OverlayTrigger>
                                </p>
                              )
                            ) : (
                              <p className='title'>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      id='tooltip-disabled'
                                      className='service-location-tooltip'
                                    >
                                      <div>
                                        <div>
                                          Parent Account Number:{" "}
                                          {facility.account_number}
                                        </div>
                                        <div className='mb-10'>
                                          <strong>
                                            Parent Account Name:{" "}
                                            {facility.account_name}
                                          </strong>
                                        </div>
                                        <div className='tooltip-adress-group'>
                                          <span>
                                            Address: {facility.street_address_1}
                                          </span>
                                          <span>
                                            {facility.street_address_2}
                                          </span>
                                        </div>

                                        <div>
                                          {facility.city}, {facility.state},{" "}
                                          {facility.zip}
                                        </div>
                                        {formattedPhone && (
                                          <div className='mb-10'>
                                            Facility Contact Phone Number:{" "}
                                            {formattedPhone}{" "}
                                            {facility.facility_phone_number_ext
                                              ? ` Ext. ${facility.facility_phone_number_ext}`
                                              : ""}
                                          </div>
                                        )}
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <span className='d-inline-block ah-sch-account-name'>
                                    2X {accountName}
                                  </span>
                                </OverlayTrigger>
                              </p>
                            )
                          ) : (
                            <React.Fragment>
                              <p className='title'>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      id='tooltip-disabled'
                                      className='service-location-tooltip'
                                    >
                                      <div>
                                        <div>
                                          Parent Account Number:{" "}
                                          {facility.account_number}
                                        </div>
                                        <div className='mb-10'>
                                          <strong>
                                            Parent Account Name:{" "}
                                            {facility.account_name}
                                          </strong>
                                        </div>
                                        <div className='tooltip-adress-group'>
                                          <span>
                                            Address: {facility.street_address_1}
                                          </span>
                                          <span>
                                            {facility.street_address_2}
                                          </span>
                                        </div>

                                        <div>
                                          {facility.city}, {facility.state},{" "}
                                          {facility.zip}
                                        </div>
                                        {formattedPhone && (
                                          <div className='mb-10'>
                                            Facility Contact Phone Number:{" "}
                                            {formattedPhone}{" "}
                                            {facility.facility_phone_number_ext
                                              ? ` Ext. ${facility.facility_phone_number_ext}`
                                              : ""}
                                          </div>
                                        )}
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <span className='d-inline-block ah-sch-account-name'>
                                    {service_stop_day == 2 &&
                                      event_has_facility_notes
                                      ? `(${event_has_facility_notes})`
                                      : ""}{" "}
                                    {accountName}
                                  </span>
                                </OverlayTrigger>
                              </p>
                              <span className='service-icon'>
                                <span className='visually-hidden'>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip
                                        id='tooltip-disabled'
                                        className='service-location-tooltip'
                                      >
                                        <div>
                                          <div>
                                            Parent Account Number:{" "}
                                            {facility.account_number}
                                          </div>
                                          <div className='mb-10'>
                                            <strong>
                                              Parent Account Name:{" "}
                                              {facility.account_name}
                                            </strong>
                                          </div>
                                          <div className='tooltip-adress-group'>
                                            <span>
                                              Address:{" "}
                                              {facility.street_address_1}
                                            </span>
                                            <span>
                                              {facility.street_address_2}
                                            </span>
                                          </div>

                                          <div>
                                            {facility.city}, {facility.state},{" "}
                                            {facility.zip}
                                          </div>
                                          {formattedPhone && (
                                            <div className='mb-10'>
                                              Facility Contact Phone Number:{" "}
                                              {formattedPhone}{" "}
                                              {facility.facility_phone_number_ext
                                                ? ` Ext. ${facility.facility_phone_number_ext}`
                                                : ""}
                                            </div>
                                          )}
                                        </div>
                                      </Tooltip>
                                    }
                                  >
                                    <span className='d-inline-block ah-sch-account-name'>
                                      {accountName}
                                    </span>
                                  </OverlayTrigger>
                                </span>
                              </span>
                            </React.Fragment>
                          )}
                          {sectionType === BACKLOG_SECTION_TYPE ? (
                            service_stop_day == 2 ? (
                              users.length > 1 ? (
                                <div className='states-combo'>
                                  {check_in_count > 0 &&
                                    check_out_count > 0 &&
                                    ((check_in_count === 1 &&
                                      check_out_count === 1) ||
                                      (check_in_count === 2 &&
                                        check_out_count === 2)) ? (
                                    <span className='service-icon icon sf'>
                                      <span className='user-count'>
                                        {check_out_count}
                                      </span>
                                    </span>
                                  ) : check_in_count > check_out_count &&
                                    check_out_count > 0 ? (
                                    <>
                                      <span className='service-icon2 icon ass'>
                                        <span className='user-count'>
                                          {check_in_count > check_out_count
                                            ? 1
                                            : check_in_count}
                                        </span>
                                      </span>
                                      <span className='service-icon icon'>
                                        <span className='user-count'>
                                          {check_out_count}
                                        </span>
                                      </span>
                                    </>
                                  ) : check_in_count > check_out_count &&
                                    check_out_count === 0 ? (
                                    <span className='service-icon2 icon dfg'>
                                      <span className='user-count'>
                                        {check_in_count}
                                      </span>
                                    </span>
                                  ) : null}
                                </div>
                              ) : users.length === 1 ? (
                                check_in_count == 1 && check_out_count == 0 ? (
                                  <div className='states-combo pr-0 ila'>
                                    <span className='service-icon icon checked-in'>
                                      <span className='user-count'>1</span>
                                    </span>
                                    <span className='service-icon icon drag'>
                                      <span className='visually-hidden'>
                                        Icon Drag
                                      </span>
                                    </span>
                                  </div>
                                ) : check_in_count == 1 &&
                                  check_out_count == 1 ? (
                                  <div className='states-combo pr-0 pila'>
                                    <span className='service-icon icon checked-out'>
                                      <span className='user-count'>1</span>
                                    </span>
                                    <span className='service-icon icon drag'>
                                      <span className='visually-hidden'>
                                        Icon Drag
                                      </span>
                                    </span>
                                  </div>
                                ) : null
                              ) : null
                            ) : null
                          ) : null}
                          {sectionType === BACKLOG_SECTION_TYPE &&
                            (service_stop_day == 1 ||
                              (service_stop_day == 2 &&
                                check_in_count == 0 &&
                                check_out_count == 0)) && (
                              <span className='service-icon'>
                                <span className='visually-hidden'>
                                  Icon Drag
                                </span>
                              </span>
                            )}
                        </div>
                      </div>
                      {sectionType !== BACKLOG_SECTION_TYPE &&
                        lastFacilityId == facility_id &&
                        showDropArea && (
                          <div
                          // className={`${snapshot.isDraggingOver ? "droppable-area-active " : " " }`}
                          >
                            <p className='drop-facility-zone-text'>
                              Drag facilities here from the{" "}
                              <span className='roboto-medium'>Task Bar</span> to
                              add to a person’s schedule
                            </p>
                          </div>
                        )}
                    </div>
                  </Popover>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
});
